import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../../auth/services/auth/auth.service';
import { UserPermissionsService } from '../../../../services/users/permissions/user-permissions.service';
import { WindowService } from '../../../../services/window/window.service';
import { UserSettingsService } from 'src/app/core/services/users/settings/user-settings.service';

@Component({
    selector: 'app-base',
    templateUrl: './base.component.html',
    styleUrls: ['./base.component.scss'],

})
export class BaseComponent implements OnInit {

    constructor(
        private AuthService:AuthService,
        private Router: Router,
        private UserPermissionsService: UserPermissionsService,
        private WindowService: WindowService,
        private UserSettingsService:UserSettingsService
    ) { }
    
    isLoggedIn:boolean = false;

    ngOnInit(): void {
        
        this.UserPermissionsService.userRoleChecks();

        if ( this.AuthService.isLoggedOut() ) {
            this.Router.navigateByUrl('auth/login');
            return;
        }

        this.isLoggedIn = true
        this.UserSettingsService.getSettings()
        this.AuthService.authLoop()
        this.WindowService.getWindowInnerWidth()
    }

}
