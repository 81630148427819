
<div class="notifications">

    <div class="not-inner-position">
        <ng-container *ngFor="let notification of notifications">
    
            <div [@simpleFadeAnimation]="'in'" class="n-notice {{notification.type}}">
                <div class="n-n-inner">

                    <div class="n-n-i-icon" [ngSwitch]="notification.type">
                        <i *ngSwitchCase="'success'" class="fa-solid fa-check"></i>
                        <i *ngSwitchCase="'info'" class="fa-solid fa-circle-info"></i>
                        <i *ngSwitchCase="'warning'" class="fa-solid fa-triangle-exclamation"></i>
                        <i *ngSwitchCase="'error'" class="fa-solid fa-xmark-large"></i>
                    </div>
                    
                    <div class="n-n-i-message">
                        {{notification.message}}
                    </div>
                </div>
            </div>
    
        </ng-container>
    </div>

</div>