
<app-notifications></app-notifications>

<div class="base-content-wrap">
    <app-sidebar></app-sidebar>
    <app-mobile-menu></app-mobile-menu>

    <div class="base-outer">
        <div class="bo-size-grow-flex">
            <div class="bo-size">
                <div class="bo-flex">
                    <router-outlet></router-outlet>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <app-footer>
    
</app-footer> -->