
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { DateTime } from 'luxon';
import {  Subscription } from 'rxjs';
import { NotificationsService } from '../../../../services/notifications/notifications.service';

@Component({
    selector: 'app-notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.scss'],
    animations: [
        // the fade-in/fade-out animation.
        trigger('simpleFadeAnimation', [

        // the "in" style determines the "resting" state of the element when it is visible.
        state('in', style({opacity: 1})),

        // fade in when created. this could also be written as transition('void => *')
        transition(':enter', [
            style({opacity: 0}),
            animate(200)
        ]),

        // fade out when destroyed. this could also be written as transition('void => *')
        transition(':leave',
            animate(400, style({opacity: 0})))
        ])
    ]
})
export class NotificationsComponent implements OnInit, OnDestroy {

    constructor(
        private NotificationsService:NotificationsService
    ) { }

    ngOnDestroy(): void {
        this.subNotifications.unsubscribe();
    }

    ngOnInit(): void {
        this.subscriptions();
        this.notificationRemover();
    }

    notifications:any;
    subNotifications!: Subscription;
    subscriptions() {
        this.subNotifications = this.NotificationsService.sharedNotifications.subscribe( (data:any) => {
            if ( data === undefined || data == null || data.length < 1 ) return;
            if ( this.notifications === undefined ) this.notifications = [];
            this.notifications.push(data);
            this.notificationRemover();
        });
    }

    notificationInterval:any = false;
    notificationRemover() {

        // If the interval is already running, exit.
        if ( this.notificationInterval != false ) return;

        // Start the interval!
        this.notificationInterval = setInterval( () => {
            
            if ( this.notifications === undefined || this.notifications.length < 1 ) return;

            const dateStart = DateTime.now();
            
            // Loop backwards to keep the index intact
            for (let i = this.notifications.length - 1; i >= 0; i--) {
                const notification:any = this.notifications[i];
                
                const dateEnd = notification.addedDateTime;
                const diff = dateStart.diff(dateEnd, ["milliseconds"])
                const ms:any = diff.toObject().milliseconds;
                
                if ( ms > 2000 ) this.notifications.splice(i, 1);
            }

            if ( this.notifications === undefined || this.notifications.length < 1 ) {
                clearInterval(this.notificationInterval);
                this.notificationInterval = false;
                return;
            }

        }, 200);
    }

}